import {
  FETCH_USERS_FAILED,
  FETCH_USERS_PENDING,
  FETCH_USERS_SUCCESS,
  UPDATE_USERS_FAILED,
  UPDATE_USERS_PENDING,
  UPDATE_USERS_SUCCESS,
} from "./types";
import UserAPI from "../../api/userAPI";

export const fetchUsers = async (dispatch, query) => {
  dispatch({ type: FETCH_USERS_PENDING });

  try {
    const res = await UserAPI.getAllUsers(query);
    dispatch({ type: FETCH_USERS_SUCCESS, payload: res });
  } catch (error) {
    console.log("err", error);
    dispatch({ type: FETCH_USERS_FAILED });
  }
};

export const updateStatusUser = async (dispatch, userId, statusObj) => {
  // dispatch({ type: UPDATE_USERS_PENDING });
  try {
    const { data } = await UserAPI.updateUser(userId, statusObj);
    dispatch({ type: UPDATE_USERS_SUCCESS, payload: data });
  } catch (error) {
    console.log("err", error);
    // dispatch({ type: UPDATE_USERS_FAILED });
  }
};
