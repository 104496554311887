import axios from "axios";
//import authHeader from "../../helpers/header";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { API_URL, MEDIA_URL } from "../../configs";

const InputWithCopyButton = ({ initialText }) => {
    const [text, setText] = useState(initialText);
    const [isCopied, setIsCopied] = useState(false);
  
    const handleCopyClick = () => {
      const input = document.createElement('input');
      input.value = text;
  
      input.style.position = 'absolute';
      input.style.left = '-9999px';
  
      document.body.appendChild(input);
  
      input.select();
      document.execCommand('copy');
  
      document.body.removeChild(input);
  
      setIsCopied(true);
  
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };
  
    const handleTextChange = (event) => {
      setText(event.target.value);
    };
  
    return (
      <div className="d-flex g-3 align-items-center">
        <input type="text" className="form-control" value={text} onChange={handleTextChange} />
        <button className="btn btn-secondary" onClick={handleCopyClick} disabled={isCopied}>
            {isCopied ? 
            <i className="bi bi-check text-white fs-6"></i>
            : 
            <i className="bi bi-clipboard fs-6"></i>
          }
        </button>
      </div>
    );
};

const TextAreaWithCopyButton = ({ initialText, label, wrapClass }) => {
    const [text, setText] = useState(initialText);
    const [isCopied, setIsCopied] = useState(false);
  
    const handleCopyClick = () => {
      const textarea = document.createElement('textarea');
      textarea.value = text;
  
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
  
      document.body.appendChild(textarea);
  
      textarea.select();
      document.execCommand('copy');
  
      document.body.removeChild(textarea);
  
      // Update the state to indicate that the text has been copied
      setIsCopied(true);
  
      // Reset the isCopied state after a brief period (e.g., 2 seconds)
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };
  
    const handleTextChange = (event) => {
      setText(event.target.value);
    };
  
    return (
      <div className={`${wrapClass ? wrapClass : ''}`}>
        <label htmlFor="label" className="form-label text-muted">
            {label}
        </label>
        <textarea className="form-control text-sm mb-2" value={text} onChange={handleTextChange} rows={4} cols={5} />
        <button className="btn btn-secondary" onClick={handleCopyClick} disabled={isCopied}>
          {isCopied ? 
            <i className="bi bi-check text-white fs-6"></i>
            : 
            <i className="bi bi-clipboard fs-6"></i>
          }
        </button>
      </div>
    );
  };

function ResourcesProcess(props) {
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    //const [imageUrl, setImageUrl] = useState(null);
    const [data, setData] = useState([]);

    const fileDownloadHandler = async (pictures) => {
        for (var i = 0; i< pictures.length; i++) {
            console.log(pictures[i].slug)
            let pictureUrl = `${MEDIA_URL}${pictures[i].coverImages.path}`
            //render output name = slug + content type
            let output = pictures[i].slug
           
            //const response = await fetch(`http://localhost:3001/proxy-image?${pictureUrl}`);
            //const response = await fetch(`https://api.codetabs.com/v1/proxy?quest=${pictureUrl}`);
            // const response = await fetch(`${pictureUrl}`);
            // response.blob().then(blob => {
            //     let url = window.URL.createObjectURL(blob);
            //     let a = document.createElement('a');
            //     a.href = url;
            //     a.download = `${output}`;
            //     a.click();
            // });

            {/* Download to jpg and resize */}
            const response = await fetch(`${pictureUrl}`);
            response.blob().then(async (blob) => {
                const img = document.createElement('img');
                img.src = URL.createObjectURL(blob);

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Determine the size of the square
                    const size = Math.max(img.width, img.height);

                    // Set canvas size to the determined square size
                    canvas.width = size;
                    canvas.height = size;

                    // Fill canvas with white background
                    ctx.fillStyle = '#FFFFFF';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    // Calculate position to center the image
                    const xOffset = (size - img.width) / 2;
                    const yOffset = (size - img.height) / 2;

                    // Draw the image on the canvas at the center
                    ctx.drawImage(img, xOffset, yOffset);

                    // Convert canvas content to a blob (JPG format with quality 0.9)
                    canvas.toBlob((newBlob) => {
                        let url = window.URL.createObjectURL(newBlob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = `${output}.jpg`; // Set the desired download filename with the .jpg extension
                        a.click();
                    }, 'image/jpeg', 0.9);
                };
            });

            // saveAs( blob,
            //     `${output}`);
      }
    }

    const handleDownload = ()=>{
        fileDownloadHandler(data)
    }

    useEffect(() => {
        let ids = searchParams.get('resources_id')
        //const fetchRelatedTags = async (tag) => {
        async function getResourcesImages(ids) {
            const res = await axios.get(API_URL + `resources/images/?ids=${ids}`)
            if(res.status === 200){
                setData(res.data)
            }
        }
        getResourcesImages(ids)
        console.log(data)
    }, [])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Test page"}</h2>
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                        handleDownload()
                    }}
                  >
                    Download images
                </button>
            </header>
            <div>
                {data && 
                <div className="row">
                    {data.map((data, index) => {
                        return(
                            <div key={index} className="col-md-2">
                                <img className="img-thumbnail" src={`${MEDIA_URL}${data.coverImages.path}`} with="200" />
                                <p className="text-muted text-center mt-2 mb-1 py-0">
                                    <Link to={`/resources/view/${data._id}`} target="_blank" rel="noreferrer">
                                        <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data._id}</span>
                                    </Link>
                                </p>
                                <p className="text-muted text-center mb-2 py-0">
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2 text-uppercase">{data.coverImages.contentType}</span>
                                    <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{data.coverImages.width}{' x '}{data.coverImages.height}</span>
                                </p>
                                <TextAreaWithCopyButton 
                                wrapClass="mb-2"
                                label={'Embeded'}
                                initialText={`<a href='https://magipik.com/vector/${data.slug}' title='${data.slug.replace('-', ' ')}' target="_blank"><img src='https://media.magipik.com/${data.thumbnail.path}' alt='${data.slug.replace('-', ' ')}' /></a>`} />
                                <InputWithCopyButton initialText={`https://magipik.com/vector/${data.slug}`} />
                            </div>
                        )
                    })
                    }
                </div>
                }
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ResourcesProcess);
      