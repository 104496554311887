import axios from "axios";
import authHeader from "../helpers/header";
// import helper from "../helpers/query";
// import apiInstance from "./api";

import { API_URL } from "../configs";

// change delete method to update status
const createNewCollection = (data) => {
  return axios.post(
    API_URL + `collections/`,
    {
      data,
    },
    { headers: authHeader() }
  );
};

const exportedObject = {
  createNewCollection,
};

export default exportedObject;
