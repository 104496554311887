import { useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { logout } from "../../redux/actions/auth";
import styles from "./Topbar.module.css";

const Topbar = ({ show, handleToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = useCallback(() => {
    dispatch(logout());
    navigate("/login", { replace: true });
  }, [dispatch]);

  return (
    <header className={`${styles.topbar} ${show ? styles.show : ""}`}>
      <button className={styles.toggleBtn} onClick={() => handleToggle()}>
        <i className="bi bi-list"></i>
      </button>
      <Dropdown className={`${styles.userMenu} pe-sm-5`}>
        <Dropdown.Toggle variant="default" id="dropdown-basic">
          <i className="bi bi-person-fill me-2"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href={"/profile"} className="py-1 text-muted">
            <i className="bi bi-person-vcard me-3"></i>
            <span className="small">{"Profile"}</span>
          </Dropdown.Item>
          <Dropdown.Item href="/profile/settings" className="py-1 text-muted">
            <i className="bi bi-gear-wide-connected me-3"></i>
            <span className="small">{"Settings"}</span>
          </Dropdown.Item>
          <Dropdown.Item
            href={""}
            className="py-1 text-muted"
            onClick={(e) => {
              e.preventDefault();
              logOut();
            }}
          >
            <i className="bi bi-door-open-fill me-3"></i>
            <span className="small">{"Log out"}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </header>
  );
};

export default Topbar;
