import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import {
  LineChart,
  Line,
  BarChart,
  PieChart,
  Pie,
  Bar,
  Label,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { API_URL, MEDIA_URL } from "../../configs";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import format from "../../helpers/format";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const renderPieLabel = (entry) => {
  console.log(entry);
  return entry.name + entry.value;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${value}`}
    </text>
  );
};

const Home = () => {
  const [uploadData, setUploadData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [userStatData, setUserStatData] = useState([]);
  const [topUserStatData, setTopUserStatData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [fileStatData, setFileStatData] = useState([]);

  const getUploadStat = async () => {
    let data = [];
    //get resources report data
    await axios
      .get(API_URL + `reports/uploaded/stats`)
      .then((response) => {
        response.data.map((value, index) => {
          data.push({
            date: value._id,
            Total: value.total,
            Active: value.active,
            Deleted: value.deleted,
          });
        });
        setUploadData(data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const getUsersStat = async () => {
    let data = [];
    //get resources report data
    await axios
      .get(API_URL + `reports/users`)
      .then((response) => {
        response.data.map((value, index) => {
          data.push({
            date: value._id,
            Total: value.total,
          });
        });

        setUserStatData(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getTopUsersStat = async () => {
    //get resources report data
    await axios
      .get(API_URL + `reports/users/statitics?limit=5`)
      .then((response) => {
        setTopUserStatData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getTopDownload = async () => {
    //get resources report data
    await axios
      .get(API_URL + `reports/downloaded`)
      .then((response) => {
        setDownloadData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getRecentUpload = async () => {
    //get resources report data
    await axios
      .get(API_URL + `reports/resources/recents`)
      .then((response) => {
        setRecentData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getRecentSearch = async () => {
    //get resources report data
    await axios
      .get(API_URL + `reports/search/recents`)
      .then((response) => {
        setSearchData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getFileStat = async () => {
    let data = [];
    //get resources report data
    await axios
      .get(API_URL + `reports/resources/fileformat`)
      .then((response) => {
        //console.log(response.data);
        response.data.map((value, index) => {
          data.push({
            Name: value._id,
            Total: value.total,
          });
        });
        setFileStatData(data);
      })
      .catch((e) => {
        console.log("error file start:", e);
      });
  };

  useEffect(async () => {
    await getUploadStat();
    await getUsersStat();
    await getTopUsersStat();
    await getTopDownload();
    await getFileStat();
    await getRecentUpload();
    await getRecentSearch();
  }, []);

  return (
    <AuthLayout>
      <div className="row py-4 gx-5">
        <div className="col-md-9 col-sm-12">
          <div className="chart-block p-md-4 p-sm-0">
            <p className="mb-4">
              <strong>Upload statitics</strong>
            </p>
            <div className="my-md-5 px-md-4">
              <ResponsiveContainer aspect={4}>
                <BarChart
                  data={uploadData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid stroke="#ebebeb" />
                  <XAxis
                    dataKey="date"
                    stroke="#d3d6d8"
                    style={{
                      fontSize: "0.7rem",
                      fontFamily: "Inter",
                    }}
                  />
                  <YAxis
                    stroke="#d3d6d8"
                    label={{
                      value: "Number of resources",
                      angle: -90,
                      offset: 0,
                      position: "insideLeft",
                    }}
                    style={{
                      fontSize: "0.7rem",
                      fontFamily: "Inter",
                    }}
                  />
                  <Tooltip cursor={{ fill: "#e2e3e5" }} />
                  <Legend verticalAlign="top" height={42} />
                  <Bar dataKey="Active" fill="#00C49F" barSize={5} />
                  <Bar dataKey="Deleted" fill="#e35d6a" barSize={5} />
                  <Bar dataKey="Total" fill="#0088FE" barSize={5} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="chart-block  p-md-4 p-sm-0">
            <p className="mb-4">
              <strong>Users statitics</strong>
            </p>
            <div className="my-md-5 px-md">
              <ResponsiveContainer aspect={4}>
                <LineChart data={userStatData}>
                  <XAxis
                    dataKey="date"
                    stroke="#d3d6d8"
                    style={{
                      fontSize: "0.7rem",
                      fontFamily: "Inter",
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Registered users",
                      angle: -90,
                      offset: 0,
                      position: "insideLeft",
                    }}
                    stroke="#d3d6d8"
                    style={{
                      fontSize: "0.7rem",
                      fontFamily: "Inter",
                    }}
                  />
                  <CartesianGrid stroke="#f2f2f2" />
                  <Tooltip cursor={{ fill: "#e2e3e5" }} />
                  <Line
                    type="monotone"
                    dataKey="Total"
                    stroke="#00C49F"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="chart-block p-md-4 p-sm-0">
                <p className="mb-4">
                  <strong>Files statitics</strong>
                </p>
                <ResponsiveContainer aspect={4}>
                <PieChart width={400} height={400}>
                  <Legend
                    layout="horizontal"
                    verticalAlign="top"
                    align="center"
                  />
                  <Pie
                    dataKey="Total"
                    nameKey="Name"
                    data={fileStatData}
                    fill="#00C49F"
                    labelLine={false}
                    label={renderCustomizedLabel}
                  >
                    {fileStatData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                  </Pie>
                </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mb-4">
              <div className="p-4 border rounded">
                <div className="d-flex justify-content-between mb-4 border-bottom pb-3">
                  <strong>Top users</strong>
                  <span className="text-muted">
                    <i className="bi bi-three-dots-vertical"></i>
                  </span>
                </div>
                {topUserStatData && (
                  <ul className="list-unstyled w-full">
                    {topUserStatData.map((userStat, index) => {
                      return (
                        <li
                          className="mb-4 d-flex justify-content-start align-items-center w-full"
                          key={index}
                        >
                          <div className="avatar">
                            {userStat.creator.avatar ? (
                              <>
                                <img
                                  className="rounded-circle"
                                  src={`https://media.magipik.com/${userStat.creator.avatar}`}
                                  width={36}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  className="d-flex border mx-auto align-items-center justify-content-center rounded-circle bg-light text-secondary p-2"
                                  style={{ width: 36, height: 36 }}
                                >
                                  <i className="bi bi-person-fill"></i>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="align-items-center ms-4">
                            <p className="mb-1">
                              <Link to={`/users/view/${userStat.creator._id}`}>
                                {userStat.creator.username}
                              </Link>
                            </p>
                            <p className="small mb-0">
                              <span className="text-muted me-4">
                                <i className="bi bi-file-arrow-up me-1"></i>
                                <span className="small">
                                  {userStat.totalUpload}
                                </span>
                              </span>
                              <span className="text-muted me-4">
                                <i className="bi bi-eye me-1"></i>
                                <span className="small">
                                  {userStat.totalView}
                                </span>
                              </span>
                              <span className="text-muted me-4">
                                <i className="bi bi-heart me-1"></i>
                                <span className="small">
                                  {userStat.totalLike}
                                </span>
                              </span>
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            <div className="col-md-3 col-sm-12 mb-4">
              <div className="p-4 border rounded">
                <div className="d-flex justify-content-between mb-4 border-bottom pb-3">
                  <strong>Recent search</strong>
                  <span className="text-muted">
                    <i className="bi bi-three-dots-vertical"></i>
                  </span>
                </div>
                <ul className="list-unstyled w-full">
                  {searchData &&
                    searchData.map((search, index) => {
                      return (
                        <li
                          className="mb-2 d-flex justify-content-start align-items-center w-full"
                          key={index}
                        >
                          <p className="mb-1">
                            <span className="me-2 small">
                              {search.search_query}
                            </span>
                            <span className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                              <i className="bi bi-list-task me-1"></i>
                              <span className="small">
                                {search.results_count}
                              </span>
                            </span>

                            <span className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                              <i className="bi bi-clock me-1"></i>
                              <span className="small">
                                <TimeAgo date={search.timestamp} />
                              </span>
                            </span>
                          </p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-12 mb-4">
          <div className="mb-4 px-4 py-3 bg-white border rounded">
            <div className="d-flex justify-content-between mb-4 border-bottom pb-2">
              <strong>Recent uploaded</strong>
              <span className="text-muted">
                <i className="bi bi-three-dots-vertical"></i>
              </span>
            </div>
            {recentData && (
              <ul className="list-unstyled">
                {recentData.map((resource, index) => {
                  return (
                    <li
                      className="mb-2 d-flex justify-content-start align-items-center"
                      key={index}
                    >
                      <div className="text-center">
                        {resource?.thumbnail && (
                          <>
                            <Link to={`/resources/edit/${resource._id}`}>
                              <img
                                src={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                width={64}
                                className="mx-auto my-2 img-fluid px-8"
                              />
                              {/* <PreviewImage image={`${MEDIA_URL}${resource.resourcePreview[0].path}`} style={{width:`100%`}} /> */}
                            </Link>
                          </>
                        )}
                      </div>
                      <div className="col-md-10 ms-4">
                        <p className="mb-1 small list-title">
                          <Link
                            to={`/resources/edit/${resource._id}`}
                            className="text-dark"
                          >
                            {resource.name}
                          </Link>
                        </p>
                        <p className="text-muted mb-2">
                          {format.formatResourceStatusNoText(resource.status)}
                          {resource.creator && (
                            <>
                              <span className="mx-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                <i className="bi bi-person me-1"></i>
                                <span className="">
                                  {resource.creator.username}
                                </span>
                              </span>
                            </>
                          )}
                          <span className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                            <i className="bi bi-clock me-1"></i>
                            <span className="">
                              <TimeAgo date={resource.created_at} />
                            </span>
                          </span>
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="mb-4 px-4 py-3 bg-white border rounded">
            <div className="d-flex justify-content-between mb-4 border-bottom pb-3">
              <strong>Top download</strong>
              <span className="text-muted">
                <i className="bi bi-three-dots-vertical"></i>
              </span>
            </div>
            {downloadData && (
              <ul className="list-unstyled">
                {downloadData.map((resource, index) => {
                  return (
                    <li
                      className="mb-2 d-flex justify-content-start align-items-center"
                      key={index}
                    >
                      <div className="text-center">
                        {resource.resourcePreview &&
                          resource.resourcePreview[0] && (
                            <>
                              <img
                                src={`${MEDIA_URL}${resource.resourcePreview[0].path}`}
                                height={64}
                                className="img-fluid my-2"
                              />
                              {/* <PreviewImage image={`${MEDIA_URL}${resource.resourcePreview[0].path}`} style={{width:`100%`}} /> */}
                            </>
                          )}
                      </div>
                      <div className="col-md-10 ms-4">
                        <p className="mb-1 small list-title">
                          <Link
                            to={`/resources/view/${resource._id}`}
                            className="text-dark"
                          >
                            {resource.resourceName}
                          </Link>
                        </p>
                        <p className="small text-muted mb-0">
                          <span className="me-2 badge rounded-pill bg-success bg-opacity-10 text-success py-1 px-2 fw-normal">
                            <i className="bi bi-arrow-down-short me-1"></i>
                            {resource.totalDownload}
                          </span>
                          {resource.creator[0] && (
                            <>
                              <span className="mx-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                <i className="bi bi-person me-1"></i>
                                <span>{resource.creator[0].username}</span>
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Home;
