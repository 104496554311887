import axios from "axios";
//import authHeader from "../../helpers/header";

import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { Table } from "react-bootstrap";
import { API_URL } from "../../configs";

function ReportFile(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        //const fetchRelatedTags = async (tag) => {
        async function fetchRelatedTags() {
            const res = await axios.get(API_URL + `reports/files_format/`)
            console.log(res.data)
            if(res.status == 200){
                setData(res.data[0].data)
                // if(res.data[0].metadata[0]) {
                //     setCount(res.data[0].metadata[0].totalCount)
                // }
            }
        }
        fetchRelatedTags('social')
        //console.log(data)
    }, [])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Report"}</h2>
            </header>
            <div>
                <div className="mb-3">
                    <Link 
                        to={`/reports/dump`} 
                        target="_blank" 
                        rel="noreferrer"
                        className="btn btn-secondary"
                    >
                        Seo Urls
                    </Link>
                </div>
                {data &&
                <>
                 <Table className="table-hover table-striped mb-4" responsive>
                <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">{'Resources'}</th>
                    <th scope="col" className="fw-normal text-muted">{'AI'}</th>
                    <th scope="col" className="fw-normal text-muted">{'EPS'}</th>
                    <th scope="col" className="fw-normal text-muted">{'SVG'}</th>
                  </tr>
                </thead>
                <tbody>
                {
                data.map((item, index) => {
                    return(
                        <tr key={index}>
                            <td>
                                <Link to={`/resources/view/${item._id}`} target="_blank" rel="noreferrer">
                                 {item._id}
                                </Link>
                            </td>
                            <td>{item.ai}</td>
                            <td>{item.eps}</td>
                            <td>{item.svg}</td>
                        </tr>
                    )
                })
                }
                </tbody>
                </Table>
                </>
                }
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ReportFile);
      