export const SITE_NAME = process.env.REACT_APP_SITE_NAME;
export const API_URL = process.env.REACT_APP_API_SERVER_URL;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_SERVER_URL;
export const FILE_URL = process.env.REACT_APP_FILE_SEVER_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_SEVER_URL;
export const S3_KEY = process.env.REACT_APP_AWS_S3_ACCESS_KEY;
export const S3_SECRET = process.env.REACT_APP_AWS_S3_SECRET_KEY;
export const S3_REGION = process.env.REACT_APP_AWS_S3_REGION;
export const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
export const S3_ENDPOINT = process.env.REACT_APP_AWS_S3_ENDPOINT;
export const FE_WEBSITE_URL = process.env.REACT_APP_FE_WEBSITE_URL;
export const NODE_URL = process.env.REACT_APP_NODE_FILE_URL;
export const SCAN_ALL_KEY = process.env.REACT_APP_SCAN_ALL_KEY;

export const PROMPT_ALL = process.env.REACT_APP_PROMPT_ALL;
export const PROMPT_TITLE_TAGS = process.env.REACT_APP_PROMPT_TITLE_TAGS;
export const PROMPT_DESC = process.env.REACT_APP_PROMPT_DESC;
export const PROMPT_KEYWORDS = process.env.REACT_APP_PROMPT_KEYWORDS;
