import axios from "axios";
import AWS from "aws-sdk";
import fileSaver from "file-saver";
import FileAPI from "../../api/fileAPI";
import format from "../../helpers/format";
import { MEDIA_URL } from "../../configs";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

const FileItem = ({ file }) => {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(file.status);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const downloadS3 = (filepath, filename) => {
    const s3 = new AWS.S3();
    s3.config.update({
      accessKeyId: "AKIAUW5PHSEJLGCNKLN5",
      secretAccessKey: "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",
      region: "eu-central-1",
      endpoint: "s3-eu-central-1.amazonaws.com",
      signatureVersion: "v4",
    });
    const params = { Bucket: "magipik-res", Key: filepath };
    s3.getSignedUrl("getObject", params, function (err, url) {
      // console.log("Your generated pre-signed URL is", url);
      axios.get(`${url}`, { responseType: "blob" }).then((res) => {
        const blob = new File([res.data], `${filename}`);
        fileSaver.saveAs(blob);
      });
    });
  };

  const removeFile = async (fileId) => {
    await FileAPI.deleteFile(fileId)
      .then((response) => {
        handleClose();
        setStatus("deleted");
        //const res = response.data;
        // console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <li className="mb-3 d-flex justify-content-start align-items-center">
      {file.format && 
      <>
      <div className="me-4 text-muted">
        {format.formatFileEx(file.format, 'fs-4')}
      </div>
          </>
      }
      <div className="me-4">
        <Link to={`/files/view/${file._id}`}><p className="mb-1">{file.filename}</p></Link>
        <p className="small text-muted mb-0">
          <span className="me-2 pe-3 text-capitalize">
            <i className="bi bi-hdd text-muted me-2"></i>
            {file.aws_path && (
              <button
                type="button"
                className="me-2 border-0 bg-transparent"
                onClick={(e) => {
                  e.preventDefault();
                  downloadS3(file.aws_path, file.filename);
                }}
                alt={file.filename}
              >
                <span className="badge bg-warning bg-opacity-10 text-warning border border-warning">{"S3"}</span>
              </button>
            )}
            {file.path && (
              <a
                href={`${MEDIA_URL}${file.path}`}
                className="me-2"
                alt={file.filename}
                target="_blank"
                rel="noreferrer"
              >
                <span className="badge bg-secondary bg-opacity-75 text-light border border-secondary">{"Local"}</span>
              </a>
            )}
          </span>
          <span className="me-2 pe-3 text-capitalize">
           <i className="bi bi-toggle-off me-2 text-muted"></i>
            {format.formatStatus(status)}
          </span>
          <span className="me-2 pe-3">
            <i className="bi bi-clock-history text-muted me-2"></i>
            {new Date(file.created_at).toLocaleDateString()}
          </span>
          <span className="me-2 pe-3 text-capitalize">
            <i className="bi bi-app-indicator me-2"></i>
            {file.file_size > 0 ? (
              <>
                <span className="small fw-semibold">
                  {format.formatBytes(file.file_size)}
                </span>
              </>
            ) : (
              <>
                <span
                  className="bg-danger d-inline-flex rounded-circle ml-auto"
                  style={{ width: "10px", height: "10px" }}
                ></span>
              </>
            )}
          </span>
        </p>
      </div>
      {status !== "deleted" && (
        <>
          <button
            type="button"
            className="btn btn-link ms-2"
            onClick={() => {
              handleShow();
            }}
          >
            <i className="bi bi-trash2 text-muted"></i>
          </button>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <button
                type="button"
                className="btn position-absolute top-0 end-0"
                onClick={() => handleClose()}
              >
                <i class="bi bi-x-lg"></i>
              </button>
              <div className="text-center py-4 fs-6 font-semibold">
                Are you sure you want to delete the file?
              </div>
              <div className="d-flex justify-content-center pb-3">
                <button
                  type="button"
                  onClick={() => handleClose()}
                  className="btn btn-secondary mx-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={() => removeFile(file._id)}
                >
                  Delete
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </li>
  );
};

export default FileItem;
