import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { setMessage } from "../../redux/actions/message";
import { history } from "../../redux/store";
import ResourceAPI from "../../api/resourceAPI";
import FileAPI from "../../api/fileAPI";
import ResourceFiles from "../../components/Resource/Files";
import PreviewImage from "../../components/Resource/PreviewImage";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { TagsInput } from "react-tag-input-component";

import {
  MEDIA_URL,
  S3_BUCKET,
  S3_KEY,
  S3_REGION,
  S3_SECRET,
} from "../../configs";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: S3_KEY,
  secretAccessKey: S3_SECRET,
});

function ResourceEdit(props) {
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { token, user, message, messageType } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [mainImage, setMainImage] = useState(0);
  const [selectedFile, setSelectedFile] = useState([]);
  const [resourceFiles, setResourceFiles] = useState([]);
  const [resource, setResource] = useState([]);
  const [newResourceFiles, setNewResourceFiles] = useState([]);

  //tags state data
  const [selectedTags, setSelectedTags] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);

  //meta seo state data
  const [showMeta, toggleShowMeta] = useState(false);
  const watchTitle = watch("meta_title", false);

  const [progress, setProgress] = useState(0);
  const watchDescription = watch("meta_description", false);
  const tempPreviewRef = useRef([]);
  const tempFilesRef = useRef([]);

  const awsBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: S3_REGION,
  });

  //function slugify(str) {
  const slugify = async (event) => {
    let str = getValues("name");
    let slug_id = getValues("slug_id");
    // let today = Math.round((new Date()).getTime() / 1000);
    // console.log(today);
    //console.log(a)
    let slug = String(str + " " + slug_id)
      .normalize("NFKD") // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/-+/g, "-"); // remove consecutive hyphens
    setValue("slug", slug);
    event.preventDefault();
  };

  /**
   *
   * @param {string} tag
   */
  const fetchRelatedTags = async (tag) => {
    await ResourceAPI.getRelatedTag(tag)
      .then((response) => {
        //add new tags to suggested tags
        setSuggestedTags((current) => [...current, ...response.data]);
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };

  /**
   * Add tag
   * @param {array} tag
   */
  const handleTags = async (tag) => {
    //get new tag to get related
    let new_tag = tag.filter((x) => !selectedTags.includes(x));
    //process if paste value with (,)
    if (new_tag[0] && new_tag[0].split(",")) {
      //remove new_tag from tag
      tag.splice(tag.indexOf(new_tag), 1);
      //append splited value to tag
      let tmp = new_tag[0].split(",");
      tmp.map((element) => {
        //Add only none exist tag
        if (tag.indexOf(element) === -1) {
          tag.push(element);
          fetchRelatedTags(element);
        }
      });
    } else {
      //get related tag
      if (new_tag != "") {
        fetchRelatedTags(new_tag[0]);
      }
    }
    //set selected tag state
    setSelectedTags(tag);
    //process tags input value
    let tag_values = getValues("tags").split(",");

    tag.map((t) => {
      // console.log(t.length)
      if (t !== "" && !tag_values.includes(t)) {
        //avoid adding empty value
        if (getValues("tags").length > 0) {
          tag_values.push(t);
        } else {
          tag_values = [t];
        }
      }
    });
    //set tags value
    setValue("tags", tag_values.join(","));
  };

  const { previewProcess, previewProgress, previewUploadError } =
    FileAPI.useUploadPreview();
  const { fileProcess, fileProgress, fileUploadError } =
    FileAPI.useUploadFile();

  /**
   * Save resources data on submit form
   * @param {form} data
   */
  const onSubmit = async (data) => {
    // console.log("data", data);
    setIsLoading(true);

    let tagsData = [];
    let tagsArray = data.tags.split(",");
    tagsArray.map((tag) => {
      tagsData.push({ name: tag });
    });
    data.tags = tagsData;
    data.creator = { email: data.created_by };

    if (selectedFile.length > 0) {
      data.images = selectedFile;
      data.mainImage = mainImage;
    }

    await ResourceAPI.updateResource(resource._id, data)
      .then(() => {
        if (newResourceFiles.length) {
          Promise.all(
            newResourceFiles.map((file) => {
              let filename = file.filename.split(/[\\\/]/).pop();
              let file_ext = filename.split(".").pop();

              const fileData = {
                filename: filename,
                resourceId: resource._id,
                aws_path: file.aws_path,
                format: file_ext,
                file_size: file.file_size,
              };

              FileAPI.createResourceFile(fileData)
                .then((res) => {
                  if (
                    res?.data?.format === "ai" ||
                    res?.data?.format === "eps"
                  ) {
                    FileAPI.scanAiFile(res.data._id)
                      .then(() => {})
                      .catch(() => {
                        // console.log("error", `Gen file ${res.data._id} failed`);
                        dispatch(
                          setMessage(
                            `Gen metadata file ${res.data._id} failed`,
                            "danger"
                          )
                        );
                      });
                  }
                })
                .catch(function (response) {
                  dispatch(setMessage(response.message, "danger"));
                });
            })
          ).then(() => {
            dispatch(setMessage("Update successfully", "success"));
          });
        } else {
          dispatch(setMessage("Update successfully", "success"));
        }
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
    setIsLoading(false);
  };

  /**
   * Remove tag
   * @param {string} tag
   */
  const handleRemoveTag = (tag) => {
    let tag_values = getValues("new_tags").split(",");
    let index = tag_values.indexOf(tag);
    if (index > -1) {
      //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
      tag_values.splice(index, 1);
    }
    setValue("new_tags", tag_values.join(","));
    //rebuild suggested tags list
    suggestedTags.map((tg, i) => {
      //if (tg._id.includes(tag)) {
      if (tg._id.indexOf(tag) !== -1) {
        setSuggestedTags((prevState) =>
          prevState.filter((prevItem) => prevItem !== tg)
        );
      }
    });
  };

  /**
   * add suggested tag to selected
   * @param {string} tag
   */
  const addSugggestTag = (tag) => {
    if (selectedTags.indexOf(tag) === -1) {
      //add tagg to selected tags
      setSelectedTags((current) => [...current, tag]);
      //remove added tag from suggestedTags
      if (suggestedTags.length > 0) {
        suggestedTags.map((tg, i) => {
          if (tg._id === tag) {
            setSuggestedTags((current) =>
              current.filter((item, itemIndex) => itemIndex !== i)
            );
          }
        });
      }
    }
  };

  /**
   * return string of tags base tag array
   * @param {array} tags
   * @returns
   */
  const getInputTags = (tags) => {
    let tagsName = [];
    if (tags) {
      tags.map((tag) => {
        tagsName.push(tag.name);
      });
    }
    return tagsName.join(",");
  };

  /**
   * generate new metaseo data for resource
   * @param {str} resourceId
   */
  const generateMeta = async (resourceId) => {
    await ResourceAPI.generateResourceMeta(resourceId)
      .then((response) => {
        // console.log(response.status);
        if (response.status) {
          const { metaTitle, metaDescription, metaKeywords } = response.data;
          setValue("meta_title", metaTitle);
          setValue("meta_description", metaDescription);
          setValue("meta_keywords", metaKeywords);
        }
        dispatch(setMessage(`${response.data}`, "success"));
      })
      .catch((e) => {
        dispatch(setMessage(`${e}`, "danger"));
      });
  };

  const handlePreview = async (files) => {
    let tempResult = [];
    let filtedFiles = [];
    if (tempPreviewRef.current) {
      filtedFiles = [...files].filter(
        (obj1) =>
          !tempPreviewRef.current.some((obj2) => obj1.name === obj2.name)
      );
      tempPreviewRef.current = [...tempPreviewRef.current, ...filtedFiles];
    } else {
      filtedFiles = [...files];
      tempPreviewRef.current = [...files];
    }

    if (filtedFiles.length) {
      await Promise.all(
        filtedFiles.map(async (file) => {
          const formData = new FormData();
          formData.append("data", file);
          const { data } = await previewProcess(formData);

          // console.log(data);

          if (data) {
            tempPreviewRef.current.map((item) => {
              if (item.name === file.name) {
                item.filename = data.detail.filename;
                return item;
              } else {
                return item;
              }
            });

            const newPreview = {
              filename: data.detail.filename,
              sizes: [
                {
                  size: "original",
                  path: data.detail.original.path,
                  contentType: data.detail.original.contentType,
                  width: data.detail.original.width,
                  height: data.detail.original.height,
                },
              ],
            };
            if (data.detail.thumbnail) {
              newPreview.sizes.push({
                size: "small",
                path: data.detail.thumbnail.path,
                contentType: data.detail.thumbnail.contentType,
                width: data.detail.thumbnail.width,
                height: data.detail.thumbnail.height,
              });
            }
            // console.log(newPreview);
            tempResult.push(newPreview);
          }
        })
      );
      setSelectedFile([...selectedFile, ...tempResult]);
    }
  };

  const awsUpload = async (file) => {
    let path = `resources/tmp/${file.name}`;
    let params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: path,
    };
    try {
      await awsBucket
        .upload(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send(async (err, data) => {
          if (err) {
            console.log(err);
            dispatch(
              setMessage(
                "There was an error durring upload progres. Please try again!",
                "danger"
              )
            );
          } else {
            setNewResourceFiles((oldArray) => [
              ...oldArray,
              { filename: file.name, aws_path: path, file_size: file.size },
            ]);
            setResourceFiles((oldArray) => [
              ...oldArray,
              { filename: file.name, aws_path: path, file_size: file.size },
            ]);
          }
        });
    } catch (error) {
      dispatch(
        setMessage(
          "There was an error durring upload progres. Please try again!",
          "danger"
        )
      );
    }
  };

  const handleFile = (files) => {
    let filtedFiles = [];
    if (tempFilesRef.current) {
      filtedFiles = [...files].filter(
        (obj1) => !tempFilesRef.current.some((obj2) => obj1.name === obj2.name)
      );
      tempFilesRef.current = [...tempFilesRef.current, ...filtedFiles];
    } else {
      filtedFiles = [...files];
      tempFilesRef.current = [...files];
    }

    if (filtedFiles.length) {
      filtedFiles.map(async (file) => {
        await awsUpload(file);
      });
    }
  };

  const checkLength = (text, min, max) => {
    if (text.length > min && text.length < max) {
      return `<span class="badge bg-success mx-1">${text.length}</span>`;
    } else {
      return `<span class="badge bg-warning mx-1">${text.length}</span>`;
    }
  };

  useEffect(() => {
    /**
     * get resources data by id
     * @param {str} id
     */
    async function fetchData(id) {
      setIsLoading(true);

      await ResourceAPI.getResourceById(id)
        .then((res) => {
          setResource(res.data);
          setSelectedFile(res.data.images);
          setMainImage(res.data.mainImage);
          setIsLoading(false);
        })
        .catch((e) => {
          dispatch(setMessage(`${e}`, "danger"));
          setIsLoading(false);
        });
    }

    /**
     * Get file data base resource Id
     * @param {str} resourceId
     */
    // const fetchFiles = async (resourceId) => {
    async function fetchFiles(resourceId) {
      setIsLoading(true);
      await FileAPI.getResourceFiles(resourceId)
        .then((response) => {
          setResourceFiles(response.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
    fetchData(id);
    fetchFiles(id);
  }, []);

  useEffect(() => {
    if (resource.name) {
      setValue("name", resource.name);
    }
    if (resource.slug) {
      setValue("slug", resource.slug);
    }
    if (resource.creator) {
      setValue("created_by", resource.creator.email);
    }
    if (resource.tags) {
      setValue("tags", getInputTags(resource.tags));
      let rtags = getInputTags(resource.tags);
      setSelectedTags(rtags.split(","));
    }
    if (resource.description) {
      setValue("description", resource.description);
    }
    if (resource.status) {
      setValue("status", resource.status);
    }

    if (resource.metaSeo && resource.metaSeo.metaTitle) {
      setValue("meta_title", resource.metaSeo.metaTitle);
    }
    if (resource.metaSeo && resource.metaSeo.metaDescription) {
      setValue("meta_description", resource.metaSeo.metaDescription);
    }
    if (resource.metaSeo && resource.metaSeo.metaKeywords) {
      setValue("meta_keywords", resource.metaSeo.metaKeywords);
    }
    if (resource.metaSeo && resource.metaSeo.canonicalURL) {
      setValue("meta_canonicalURL", resource.metaSeo.canonicalURL);
    }
    if (resource.metaSeo && resource.metaSeo.redirectURL) {
      setValue("meta_redirectURL", resource.metaSeo.redirectURL);
    }
    if (resource.is_picked) {
      setValue("is_picked", resource.is_picked);
    }
  }, [resource]);

  const removePreview = (index) => {
    // const tempMainImage = selectedFile[mainImage];
    // tempPreviewRef.current = [
    //   ...tempPreviewRef.current.filter((item) => item.filename !== filename),
    // ];

    // const removedPreview = selectedFile.filter(
    //   (item) => item.filename !== filename
    // );
    // const indexMainImage = removedPreview.findIndex(
    //   (item) => item.filename === tempMainImage.filename
    // );

    const removedPreviews = selectedFile.filter((_, i) => i !== index);
    setSelectedFile(removedPreviews);
    if (index < mainImage) {
      setMainImage((prev) => prev - 1);
    }
  };
  return (
    <AuthLayout>
      {isLoading ? (
        <>
          <p>loading</p>
        </>
      ) : (
        <>
          <header
            className={"d-flex justify-content-between align-items-center mb-4"}
          >
            <div className="d-flex align-items-center">
              <h2 className="mb-0 me-2">{"Edit"}</h2>
              <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                {resource.slug?.split("-").pop()}
                {/* hidden value */}
                <input
                  className="form-control"
                  type="hidden"
                  {...register("slug_id")}
                  value={resource.slug?.split("-").pop()}
                />
              </small>
            </div>
            <div className="">
              <a
                className="btn btn-secondary me-2"
                href={`/resources/view/${resource._id}`}
              >
                {"View"}
              </a>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={history.back}
              >
                {"Back"}
              </button>
            </div>
          </header>
          {message && (
            <div className="mb-4">
              <div className={`alert alert-${messageType}`} role="alert">
                {message}
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-5 me-auto">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Resource name
                  </label>
                  <input className="form-control" {...register("name")} />
                  {errors.name && (
                    <span className="small text-danger">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="slug" className="form-label">
                    Resource slug
                  </label>
                  <div className="d-flex">
                    <input className="form-control" {...register("slug")} />
                    <div>
                      <button
                        className="btn btn-link"
                        onClick={(event) => {
                          slugify(event);
                        }}
                      >
                        <i className="bi bi-check text-success fs-6"></i>
                      </button>
                    </div>
                  </div>
                  {errors.slug && (
                    <span className="small text-danger">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Descrtiption
                  </label>
                  <textarea
                    className="form-control"
                    {...register("description", { required: true })}
                    rows={4}
                  />
                  {errors.description && (
                    <span className="small text-danger">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label htmlFor="tags" className="form-label">
                      License
                    </label>
                    <select
                      className="form-select"
                      defaultValue={"free"}
                      {...register("license")}
                    >
                      <option value="free">Free</option>
                      <option value="premium">Premium</option>
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select className="form-select" {...register("status")}>
                      <option value="draft">Draft</option>
                      <option value="reject">Reject</option>
                      <option value="schedule">Schedule</option>
                      <option value="pending">Pending</option>
                      <option value="missing">Missing</option>
                      <option value="active">Active</option>
                      <option value="deleted">Deleted</option>
                    </select>
                  </div>
                  <div className="col-md-4 my-4">
                    {/* <div className="form-check py-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="is_picked"
                    aria-label="Checkbox for following text input"
                    {...register("is_picked")}
                  />
                  <label htmlFor="is_picked" className="form-check-label">
                    Picked
                  </label>
                </div> */}
                    <div className="form-check form-switch py-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="is_picked"
                        role="switch"
                        value=""
                        {...register("is_picked")}
                      />
                      <label className="form-check-label" htmlFor="is_picked">
                        Picked
                      </label>
                    </div>
                  </div>
                </div>
                {/* row */}
                <div className="mb-3">
                  <label htmlFor="tags" className="form-label">
                    Tags
                  </label>
                  <input
                    className="form-control"
                    hidden
                    {...register("tags")}
                  />
                  <TagsInput
                    value={selectedTags}
                    onChange={handleTags}
                    onRemoved={handleRemoveTag}
                    name="input-tags"
                    placeHolder="Enter tags"
                  />
                  {suggestedTags ? (
                    <div className="mt-2 tags-lbox">
                      {suggestedTags.map((tg, i) => {
                        return (
                          <span
                            key={i}
                            className="badge bg-white border rounded me-2 text-dark fw-normal fs-6 my-1 py-2 px-3 suggested-tag"
                            onClick={() => {
                              addSugggestTag(tg._id);
                            }}
                          >
                            {tg._id}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="mt-5 mb-4"
                  style={{
                    width: `100%`,
                    height: `1px`,
                    background: `#ebebeb`,
                  }}
                />
                <div className="meta">
                  <div className="d-flex justify-content-between align-items-center bg-light mb-4 py-2">
                    <strong className="px-3">Meta SEO</strong>
                    <div>
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={() => {
                          generateMeta(resource._id);
                        }}
                      >
                        <i className="bi bi-asterisk text-muted"></i>
                      </button>
                      <button
                        type="button"
                        onClick={() => toggleShowMeta(!showMeta)}
                        className="btn btn-link px-0 mx-4"
                      >
                        {showMeta ? "Show" : "Hide"}
                      </button>
                    </div>
                  </div>
                  <div
                    className={`meta-fields ${showMeta ? "d-none" : "d-block"}`}
                  >
                    <div className="mb-3">
                      <label htmlFor="tags" className="form-label">
                        Meta title
                      </label>
                      <input
                        className="form-control"
                        {...register("meta_title")}
                      />
                      <div className="text-muted text-sm my-2">
                        {"Title should be 30 to 65 characters"}
                        {/* {getValues("meta_title").length} */}
                        {watchTitle && (
                          <div
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                              __html: checkLength(watchTitle, 30, 65),
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Meta Descrtiption
                      </label>
                      <textarea
                        className="form-control"
                        {...register("meta_description")}
                        rows={4}
                      />
                      <div className="text-muted text-sm my-2">
                        {"Description should be 120 to 320 characters"}
                        {/* {getValues("meta_title").length} */}
                        {watchDescription && (
                          <div
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                              __html: checkLength(watchDescription, 120, 320),
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="tags" className="form-label">
                        Meta keywords
                      </label>
                      <input
                        className="form-control"
                        {...register("meta_keywords")}
                      />
                      <span className="text-muted text-sm py-2">
                        {
                          "The keyword should be placed at the begining of title and description"
                        }
                      </span>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="tags" className="form-label">
                        Canonical Url
                      </label>
                      <input
                        className="form-control"
                        {...register("meta_canonicalURL")}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="tags" className="form-label">
                        Redirect Url
                      </label>
                      <input
                        className="form-control"
                        {...register("meta_redirectURL")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* col */}
              <div className="col-md-6">
                <div className="mb-4">
                  <div className="mb-3">
                    <label htmlFor="pfile" className="form-label">
                      Preview file
                    </label>
                    {/* <input className="form-control" type="file" {...register("pfile")} accept=".gif,.jpg,.jpeg,.png,.doc,.docx" disabled={isLoading} onChange={(e) => handlePreview(e)} /> */}
                    <Dropzone
                      onDrop={(acceptedFiles) => handlePreview(acceptedFiles)}
                      accept={{
                        "image/png": [".png"],
                        "image/jpeg": [".jpg", ".jpeg"],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="bg-light text-center p-4 border rounded">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <span className="text-muted small">
                              Drag 'n' drop some files here, or click to select
                              files
                            </span>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {previewUploadError && (
                      <div className="alert alert-danger text-center py-2 mt-2">
                        {previewUploadError}
                      </div>
                    )}
                    {previewProgress > 0 && previewProgress < 100 && (
                      <>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${previewProgress}%` }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                          {previewProgress}
                        </div>
                      </>
                    )}
                  </div>
                  {selectedFile.length > 0 && (
                    <div className="d-flex bg-gray">
                      {selectedFile.map((preview, index) => (
                        <div className="text-center" key={index}>
                          <div className="shadow mx-2 position-relative">
                            <PreviewImage
                              image={`${MEDIA_URL}${preview.sizes[0].path}`}
                              index={index}
                              width={200}
                              height={100}
                              mainImage={mainImage}
                              setMainImage={setMainImage}
                              alt={preview.filename}
                            />
                            {mainImage !== index && (
                              <button
                                type="button"
                                className="position-absolute top-0 end-0 border-0 bg-transparent"
                                onClick={() => removePreview(index)}
                              >
                                <i className="bi bi-x-circle-fill"></i>
                              </button>
                            )}
                          </div>
                          {mainImage === index && (
                            <p className="pt-2 fw-semibold">Cover Image</p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="rfile" className="form-label">
                    Resource files
                  </label>

                  <Dropzone
                    onDrop={(acceptedFiles) => handleFile(acceptedFiles)}
                    accept={{
                      "application/postscript": [".ai", ".eps"],
                      "image/svg+xml": [".svg"],
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="bg-light text-center p-4 border rounded">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <span className="text-muted small">
                            Drag 'n' drop some files here, or click to select
                            files
                          </span>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {fileUploadError && (
                    <div className="alert alert-danger text-center py-2 mt-2">
                      {fileUploadError}
                    </div>
                  )}
                  {progress > 0 && progress < 100 && (
                    <>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${progress}%` }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                        {progress}
                      </div>
                    </>
                  )}
                </div>
                <div className="mt-4">
                  {resourceFiles && (
                    <ResourceFiles resourceFiles={resourceFiles} />
                  )}
                </div>
              </div>
              {/* col */}
            </div>
            {/* row */}
            <input
              className="form-control"
              type={"hidden"}
              {...register("created_by")}
            />
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary"
            >
              Submit
            </button>
          </form>
        </>
      )}
    </AuthLayout>
  );
}

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  const { message, messageType } = state.message;
  return {
    token,
    user,
    message,
    messageType,
  };
}

export default connect(mapStateToProps)(ResourceEdit);
