import { useEffect, useState } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import TimeAgo from "react-timeago";

import axios from "axios";
import AWS from "aws-sdk";
import fileSaver from "file-saver";
// import FileFilter from "../../components/File/FileFilter";
import format from "../../helpers/format";
import { setMessage } from "../../redux/actions/message";
import {
  MEDIA_URL,
  S3_BUCKET,
  S3_ENDPOINT,
  S3_KEY,
  S3_REGION,
  S3_SECRET,
} from "../../configs";
import Pagination from "../../components/Pagination";
import AuthLayout from "../../components/AuthLayout/AuthLayout";
// import FileSorter from "../../components/File/FileSorter";
import { Table, Dropdown } from "react-bootstrap";
import Filter from "../../components/Filter/Filter";
import Sorter from "../../components/Sorter/Sorter";
import { fetchFiles, updateFile } from "../../redux/actions/files";

const filters = [
  {
    type: "text",
    name: "q",
    defaultValue: "",
  },
  {
    type: "select",
    name: "format",
    options: [
      { title: "Select format", value: "" },
      { title: "AI", value: "ai" },
      { title: "EPS", value: "eps" },
      { title: "SVG", value: "svg" },
    ],
    defaultValue: "",
  },
  {
    type: "select",
    name: "file_size",
    options: [
      { title: "Select size", value: "" },
      { title: "Null", value: "0" },
      { title: "<= 30MB", value: "0-31457280" },
      { title: "> 30MB", value: "31457280" },
    ],
    defaultValue: "",
  },
  {
    type: "select",
    name: "storage",
    options: [
      { title: "Select storage", value: "" },
      { title: "AWS S3", value: "aws" },
      { title: "Local", value: "local" },
    ],
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "create_from",
    placeholder: "Create From",
    defaultValue: "",
  },
  {
    type: "datePicker",
    name: "create_to",
    placeholder: "Create To",
    defaultValue: "",
  },
  {
    type: "select",
    name: "status",
    options: [
      { title: "Select status", value: "" },
      { title: "Active", value: "active" },
      { title: "Deleted", value: "deleted" },
    ],
    defaultValue: "",
  },
];

const defaultFilter = filters.reduce(
  (obj, cur) => ({ ...obj, [cur.name]: cur.defaultValue }),
  {}
);

const initialQuery = {
  ...defaultFilter,
  sortby: "",
  direction: "",
  page: "",
};

const pageSize = 50;

function FileList() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { files, size, loading } = useSelector((state) => state.files);
  const { message, messageType } = useSelector((state) => state.message);
  // const [loading, setLoading] = useState(false);
  // const [files, setFiles] = useState([]);
  // const [count, setCount] = useState();
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); //helper.useQueryString("page");
  const [query, setQuery] = useState(initialQuery);
  const [fullQuery, setFullQuery] = useState({
    ...initialQuery,
    pageSize,
  });

  const handleSelectAll = () => {
    if (selectAll) {
      setSelected([]);
      setSelectAll(false);
    } else {
      const listFileSelected = files.map((item) => ({
        _id: item._id,
        status: item.status,
      }));

      setSelected(listFileSelected);
      setSelectAll(true);
    }
  };

  const handleCheckList = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      const [filterItem] = files.filter((item) => item._id === value);

      setSelected((prev) => [
        ...prev,
        { _id: filterItem._id, status: filterItem.status },
      ]);
    } else {
      setSelected((prev) => prev.filter((item) => item._id !== value));
    }
  };

  const handleActiveFile = async (file) => {
    await updateFile(dispatch, file._id, { status: "active" });
    dispatch(
      setMessage(`Actived ${file.filename} file successfully`, "success")
    );
  };

  const handleDeletedFile = async (file) => {
    await updateFile(dispatch, file._id, { status: "deleted" });
    dispatch(
      setMessage(`Deleted ${file.filename} file successfully`, "success")
    );
  };

  const handleBulkActive = async () => {
    const filterSelected = selected.filter((item) => item.status !== "active");

    if (filterSelected.length > 0) {
      await Promise.all(
        filterSelected.map(async (item) => await handleActiveFile(item))
      );
      setSelected([]);

      dispatch(
        setMessage(
          `Active ${filterSelected.length} file(s) successfully`,
          "success"
        )
      );
    } else {
      dispatch(setMessage(`Files are currently in an active status`));
    }
  };

  const handleBulkDeleted = async () => {
    const filterSelected = selected.filter((item) => item.status !== "deleted");

    if (filterSelected.length > 0) {
      await Promise.all(
        filterSelected.map(async (item) => await handleDeletedFile(item))
      );
      setSelected([]);

      dispatch(
        setMessage(
          `Deleted ${filterSelected.length} file(s) successfully`,
          "success"
        )
      );
    } else {
      dispatch(setMessage(`Files are currently in an deleted status`));
    }
  };

  // const fetchFiles = async (query = "") => {
  //   setLoading(true); //@TODO
  //   //get files data via API
  //   await FileAPI.getAllFiles(query)
  //     .then((response) => {
  //       setCount(response.size);
  //       setFiles(response.items);
  //       setLoading(false); //@TODO
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //     });
  // };

  const downloadS3 = (filepath, filename) => {
    const s3 = new AWS.S3();
    s3.config.update({
      accessKeyId: S3_KEY,
      secretAccessKey: S3_SECRET,
      region: S3_REGION,
      endpoint: S3_ENDPOINT,
      signatureVersion: "v4",
    });
    const params = { Bucket: S3_BUCKET, Key: filepath };
    s3.getSignedUrl("getObject", params, function (err, url) {
      axios.get(`${url}`, { responseType: "blob" }).then((res) => {
        const blob = new File([res.data], `${filename}`);
        fileSaver.saveAs(blob);
      });
    });
  };

  // const removeFile = async (id) => {
  //   await FileAPI.deleteFile(id)
  //     .then((response) => {
  //       if (response.status === 204) {
  //         fetchFiles(fullQuery);
  //         dispatch(setMessage(`File ${id} was deleted`, "success"));
  //       }
  //     })
  //     .catch((e) => {
  //       dispatch(setMessage(`${e}`, "danger"));
  //     });
  //   //window.location.reload(false);
  // };

  // const activeFile = async (id, data) => {
  //   await FileAPI.updateFile(id, data)
  //     .then((response) => {
  //       if (response.status === 204) {
  //         fetchFiles(fullQuery);
  //         dispatch(setMessage(`File ${id} was activated`, "success"));
  //       }
  //     })
  //     .catch((e) => {
  //       dispatch(setMessage(`${e}`, "danger"));
  //     });
  //   //window.location.reload(false);
  // };

  useEffect(async () => {
    const newQuery = Object.assign({}, query);

    Object.keys(defaultFilter).map((filter) => {
      if (searchParams.get(filter)) {
        newQuery[filter] = searchParams.get(filter);
      }
    });

    if (searchParams.get("sortby")) {
      newQuery.sortby = searchParams.get("sortby");
    }
    if (searchParams.get("direction")) {
      newQuery.direction = searchParams.get("direction");
    }

    if (searchParams.get("page")) {
      newQuery.page = searchParams.get("page");
      setCurrentPage(searchParams.get("page"));
    } else {
      //fix initial page without page params
      newQuery.page = 1;
      setCurrentPage(1);
    }
    setQuery(newQuery);

    const fullQuery = Object.assign({}, newQuery);
    fullQuery.pageSize = pageSize;
    setFullQuery(fullQuery);
    await fetchFiles(dispatch, fullQuery);
  }, [location]);

  const resetFilter = () => {
    setQuery(initialQuery);
    setFullQuery({ ...initialQuery, pageSize });
  };

  return (
    <AuthLayout>
      <header
        className={"d-flex justify-content-between align-items-center mb-4"}
      >
        <h2>{"Files"}</h2>
        <Dropdown>
          <Dropdown.Toggle variant="outline-success" className="px-4">
            Action
          </Dropdown.Toggle>

          <Dropdown.Menu className="min-w-100">
            <Dropdown.Item
              as="button"
              onClick={() => handleBulkActive()}
              disabled={!selected.length}
            >
              Active
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => handleBulkDeleted()}
              disabled={!selected.length}
            >
              Deleted
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </header>
      {message && (
        <div className="mb-4">
          <div className={`alert alert-${messageType}`} role="alert">
            {message}
          </div>
        </div>
      )}
      {/* <Search/> */}

      <div className="mb-3">
        <Filter
          defaultFilter={defaultFilter}
          filters={filters}
          reset={resetFilter}
        />
        {/* <FileFilter
          defaultFilter={query}
          filterHandle={filterHandle}
          resetHandle={handleReset}
        /> */}

        {/* <div className="col-md-2 text-right">
          <FileSorter sorterHandle={sorterHandle} />
        </div> */}
      </div>
      {loading ? (
        <p className="text-center p-5">Loading</p>
      ) : (
        <>
          {files.length > 0 ? (
            <>
              <Table responsive className="table table-striped mb-4">
                <thead>
                  <tr>
                    <th scope="col" className="fw-normal text-muted">
                      <div className="d-flex algin-items-end">
                        <button
                          className="btn py-0 px-2 text-muted"
                          title="Select All"
                          onClick={() => handleSelectAll()}
                        >
                          #
                        </button>
                      </div>
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Format
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="File name"
                        name="filename"
                      />
                    </th>
                    <th
                      scope="col"
                      className="fw-normal text-right text-muted justify-content-between"
                    >
                      <Sorter
                        defaultFilter={defaultFilter}
                        query={query}
                        title="Size"
                        name="file_size"
                      />
                    </th>
                    <th
                      scope="col"
                      className="fw-normal text-muted text-center"
                    >
                      Path
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Resource
                    </th>

                    <th scope="col" className="fw-normal text-muted">
                      <div style={{ minWidth: "95px" }}>
                        <Sorter
                          defaultFilter={defaultFilter}
                          query={query}
                          title="Created at"
                          name="created_at"
                        />
                      </div>
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Status
                    </th>
                    <th scope="col" className="fw-normal text-muted">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="border-top-0">
                  {files.map((file, index) => {
                    return (
                      <tr key={index} className={"align-middle"}>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            value={file._id}
                            onChange={(e) => handleCheckList(e)}
                            checked={selected.some(
                              (item) => item._id === file._id
                            )}
                          />
                        </td>
                        <td>{format.formatFileEx(file.format, "fs-3")}</td>
                        <td >
                          <Link to={`/files/view/${file._id}`}>
                            <strong className="rname">{file.filename}</strong>
                            <br />
                            <small className="text-muted">{file._id}</small>
                          </Link>
                        </td>
                        <td className="text-end align-middle">
                          {file.file_size > 0 ? (
                            <>
                              <span className="small fw-semibold">
                                {format.formatBytes(file.file_size)}
                              </span>
                            </>
                          ) : (
                            <>
                              <div
                                className="bg-danger d-inline-flex rounded-circle ml-auto"
                                style={{ width: "14px", height: "14px" }}
                              ></div>
                            </>
                          )}
                        </td>
                        <td className="text-center">
                          {file.aws_path && (
                            <button
                              type="button"
                              className="me-2 border-0 bg-transparent"
                              onClick={(e) => {
                                e.preventDefault();
                                downloadS3(file.aws_path, file.filename);
                              }}
                              alt={file.filename}
                            >
                              <span className="badge border border-warning bg-warning bg-opacity-10 text-warning">
                                {"S3"}
                              </span>
                            </button>
                          )}
                          {file.path && (
                            <a
                              href={`${MEDIA_URL}${file.path}`}
                              className="me-2"
                              alt={file.filename}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="badge bg-secondary bg-opacity-75 text-light border border-secondary">
                                {"Local"}
                              </span>
                            </a>
                          )}
                        </td>
                        <td>
                          <div className="align-items-center">
                            <small className="text-muted me-2">
                              {file.resourceId}
                            </small>
                            {format.formatResourceStatusNoText(
                              file.resourceStatus
                            )}
                          </div>
                          <Link to={`/resources/view/${file.resourceId}`}>
                            {file.resourceName}
                          </Link>
                        </td>
                        <td className="text-muted align-middle">
                           <Moment
                            format="YYYY/MM/DD HH:mm:ss"
                            className="small"
                          >
                            {file.created_at}
                          </Moment>
                          <br />
                          <span className="me-2 mt-1 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                            <i className="bi bi-clock me-1"></i>
                            <span className="">
                              <TimeAgo date={file.created_at} />
                            </span>
                          </span>
                          {/* {new Date(file.created_at).toDateString()} */}
                        </td>
                        <td className="align-middle">
                          {format.formatStatus(file.status)}
                        </td>
                        <td className="align-middle">
                          <div className="d-flex align-items-center">
                            {file.status === "deleted" && (
                              <button
                                className="btn fs-6 p-1"
                                onClick={() => {
                                  handleActiveFile(file);
                                }}
                                title="Active"
                              >
                                <i className="bi bi-pencil text-muted"></i>
                              </button>
                            )}

                            {file.status === "active" && (
                              <button
                                className="btn fs-6 p-1"
                                type="button"
                                title="Delete"
                                onClick={() => {
                                  handleDeletedFile(file);
                                }}
                              >
                                <i className="bi bi-trash-fill d-flex text-muted"></i>
                              </button>
                            )}

                            {/* {file.status === "deleted" && (
                              <a
                                href={""}
                                className="btn btn-sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  activeFile(file._id, { status: "active" });
                                }}
                              >
                                <i className="bi bi-pencil text-muted"></i>
                              </a>
                            )}
                            <a
                              href={""}
                              className="btn btn-sm"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFile(file._id);
                              }}
                            >
                              <i className="bi bi-trash-fill text-muted"></i>
                            </a> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                count={size}
                currentPage={currentPage}
                pageSize={pageSize}
                path={"files"}
                moduleName={"files"}
                query={query}
              />
            </>
          ) : (
            <p>{"Not found"}</p>
          )}
        </>
      )}
    </AuthLayout>
  );
}

export default FileList;
