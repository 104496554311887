import axios from "axios";
//import authHeader from "../../helpers/header";

import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { API_URL } from "../../configs";

function ReportList(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        //const fetchRelatedTags = async (tag) => {
        async function fetchRelatedTags(tag) {
            const res = await axios.get(API_URL + `resources/related_tags/${tag}`)
            //console.log(res)
            if(res.status == 200){
                setData(res.data)
            }
        }
        fetchRelatedTags('social')
        //console.log(data)
    }, [])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Report"}</h2>
            </header>
            <div>
                <div className="mb-3 d-flex">
                    <Link 
                        to={`/reports/meta`} 
                        target="_blank" 
                        rel="noreferrer"
                        className="btn btn-secondary me-2"
                    >
                        Seo meta
                    </Link>
                    <Link 
                        to={`/reports/file`} 
                        target="_blank" 
                        rel="noreferrer"
                        className="btn btn-secondary"
                    >
                        File Urls
                    </Link>
                </div>
                {data &&
                data.map((item, index) => {
                    return(
                        <div key={index}>
                            {item._id}<br />
                        </div>
                    )
                })
                }
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ReportList);
      