import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Protect from "./components/Protect";
import Home from "./pages/Home/Home";
import ProfileView from "./pages/Profile/ProfileView";
import ProfileEdit from "./pages/Profile/ProfileView";

import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";

import ReportList from "./pages/Report/ReportList";
import ReportMeta from "./pages/Report/ReportMeta";
import ReportFile from "./pages/Report/ReportFile";

//resource route
import ResourceList from "./pages/Resource/ResourceList";
import ResourceGrid from "./pages/Resource/ResourceGrid";
import ResourceCreate from "./pages/Resource/ResourceCreate";
import ResourceEdit from "./pages/Resource/ResourceEdit";
import ResourceDetail from "./pages/Resource/ResourceDetail";
import ResourcesProcess from "./pages/Resource/ResourcesProcess";

//user route
import UserList from "./pages/User/UserList";
import UserCreate from "./pages/User/UserCreate";
import UserEdit from "./pages/User/UserEdit";
import UserDetail from "./pages/User/UserDetail";

import FileList from "./pages/File/FileList";
import OrderList from "./pages/Order/OrderList";
import PaymentList from "./pages/Payment/PaymentList";
import OrderDetail from "./pages/Order/OrderDetail";
import OrderEdit from "./pages/Order/OrderEdit";
import InvoiceDetail from "./pages/Invoice/InvoiceDetail";
import ScanAI from "./pages/ScanAI/ScanAI";
import FileDetail from "./pages/File/FilesDetail";

const App = () => {
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <>
      {/* {!token ? ( */}
      {/* <Layout> */}
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <h1 className="p5 text-center">404</h1>
            </main>
          }
        />

        <Route exact element={<Protect />}>
          {/* protect router */}
          <Route exact path="/" element={<Home />} />
          <Route path="/resources">
            {/* <Route index element={<ResourceList />} /> */}
            <Route index element={<ResourceGrid />} />
            <Route exact path="new" element={<ResourceCreate />} />
            <Route exact path="view/:id" element={<ResourceDetail />} />
            <Route exact path="edit/:id" element={<ResourceEdit />} />
            <Route exact path="process" element={<ResourcesProcess />} />
          </Route>
          <Route path="/files">
            <Route index element={<FileList />} />
            <Route exact path="view/:id" element={<FileDetail />} />
            {/* <Route exact path="new" element={<FileCreate />} />
              <Route exact path="edit/:id" element={<FileEdit />} /> */}
          </Route>
          <Route path="/orders">
            <Route index element={<OrderList />} />
            <Route exact path="edit/:id" element={<OrderEdit />} />
            <Route exact path="view/:id" element={<OrderDetail />} />
          </Route>

          <Route path="/reports">
            <Route index element={<ReportList />} />
            <Route exact path="meta" element={<ReportMeta />} />
            <Route exact path="file" element={<ReportFile />} />
          </Route>

          <Route path="/invoices">
            <Route index element={<InvoiceDetail />} />
          </Route>
          <Route path="/payments">
            <Route index element={<PaymentList />} />
            {/* <Route exact path="new" element={<FileCreate />} />
              <Route exact path="edit/:id" element={<FileEdit />} /> */}
          </Route>
          <Route path="/users">
            <Route index element={<UserList />} />
            <Route exact path="new" element={<UserCreate />} />
            <Route exact path="edit/:id" element={<UserEdit />} />
            <Route exact path="view/:id" element={<UserDetail />} />
            <Route path="*" element={<Navigate to="list" />} />
          </Route>
          <Route path="/profile">
            <Route index element={<ProfileView />} />
            <Route exact path="settings" element={<ProfileEdit />} />
          </Route>
          <Route exact path="/scan-ai" element={<ScanAI />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <h1 className="p5 text-center">404</h1>
              </main>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

function mapStateToProps(state) {
  const { token } = state.auth;
  const { user } = state.user;
  const { message } = state.message;
  return {
    token,
    user,
    message,
  };
}

export default connect(mapStateToProps)(App);
